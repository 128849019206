const TimReid = {
	init: function() {
		this.bindEvents();

        if(window.scrollY > 20) {
            document.querySelector('.site-header > .is-position-fixed').classList.remove('transparent-at-top');
        }
	},

    bindEvents: function() {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 20) {
                document.querySelector('.site-header > .is-position-fixed').classList.remove('transparent-at-top');
            } else {
                document.querySelector('.site-header > .is-position-fixed').classList.add('transparent-at-top');
            }
        });

        Array.from(document.querySelectorAll('.showreel-popup-container__play')).forEach(button => {
            button.addEventListener('click', this.openModal);
        });

        Array.from(document.querySelectorAll('.showreel-popup-container dialog .close')).forEach(button => {
            button.addEventListener('click', this.closeModal);
        });

        Array.from(document.querySelectorAll('.showreel-popup-container dialog')).forEach(dialog => {
            dialog.addEventListener('close', () => {
                dialog.querySelector('video').pause();
            });
        })
    },

    openModal: function(target) {
        target.currentTarget.nextElementSibling.showModal();     
        target.currentTarget.nextElementSibling.querySelector('video').play();
    },

    closeModal: function(target) {
        target.currentTarget.nextElementSibling.querySelector('video').pause();

        target.currentTarget.parentElement.close();
    }
}

TimReid.init();